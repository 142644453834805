/* Icon Mixin */

@mixin icon($iconName, $width, $height, $retina: false) {
    display: inline-block;
    width: $width;
    height: $height;
    vertical-align: middle;
    background-image: url(../images/icons/#{$iconName}.png);
    background-size: 100% 100%;
    font-size: 0;
    line-height: 0;
    text-indent: -4004px;

    @if $retina == true {
        @media only screen and ( -webkit-min-device-pixel-ratio: 2 ), only screen and ( min-device-pixel-ratio: 2 ), only screen and ( min-resolution: 192dpi ) {
            background-image: url(../images/icons/#{$iconName}@2x.png);
        }
    }
}
