/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {
	position: fixed;
	width: 100%;
	height: 60px;
	top: 0;
	padding-top: 22px;
	left: 0;
	background: #fde3da;
	z-index: 30;

	/* Mobile */

	@include mobile {
		height: auto;
		padding: 13px 0 14px;
	}

	&__link {
		float: right;
		transition: opacity .3s;

		&:hover {
			opacity: .7;
		}
	}
	&__nav-btn {

		/* Mobile */

		@include mobile {
			float: right;
			margin-top: 16px;
		}
	}
	&__nav {
		float: right;
		padding: 5px 20px 0 0;
		margin-top: -4px;
		opacity: 0;
		visibility: hidden;
		font-size: 16px;
		transition: opacity .3s, visibility .3s;

		/* Tablet Portrait */

		@include tablet-portrait {
			padding-top: 5px;
			font-size: 13px;
		}

		/* Mobile */

		@include mobile {
			display: none;
		}

		&.nav--visible {
			opacity: 1;
			visibility: visible;
		}

		ul {
			top: 0;
		}
		li {

			+ li {
				margin-left: 57px;

				/* Small Desktop */

				@include small-desktop {
					margin-left: 25px;
				}
			}
		}
	}

	.shell {

		&:after {
			@include cf;
		}
	}
}
