/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section {
	padding: 26px 0 46px;

	/*  Modifier: Section Alt */

	&--alt {
		padding-bottom: 26px;
	}

	/* Mobile */

	@include mobile {
		padding: 30px 0;
	}

	&__group {

		&--alt {
			padding-top: 11px;

			/* Mobile */

			@include mobile {
				padding-top: 0;
			}
		}

		&:after {
			@include cf;
		}
	}
	&__aside {
		position: relative;
		float: left;
		width: 380px;

		/* Tablet Portrait */

		@include tablet-portrait {
			width: 260px;
			left: 0;
		}

		/* Mobile */

		@include mobile {
			float: none;
			width: 100%;
			margin-bottom: 21px;
		}
	}
	&__content {
		float: right;
		width: calc(100% - 380px);
		padding: 1px 0 0 13px;

		/* Tablet Portrait */

		@include tablet-portrait {
			width: calc(100% - 260px);
		}

		/* Mobile */

		@include mobile {
			float: none;
			width: 100%;
			padding: 0;
		}

		&:before {
			content: '';
			display: inline-block;
			width: 8px;
			height: 26px;
			margin-bottom: 14px;
			vertical-align: middle;
			background-image: url(../images/icons/ico-arrow-down.png);
			background-size: 100% 100%;

			/* Retina Display */

			@include retina {
				background-image: url(../images/icons/ico-arrow-down@2x.png);
			}

			/* Mobile */

			@include mobile {
				display: none;
			}
		}

		.section__content-entry {
			margin-bottom: -30px;

			/* Mobile */

			@include mobile {
				margin-bottom: 15px;
			}

			em {

				&:after {
					height: 30px;
				}
			}
		}

		p {
			column-count: 2;
			column-gap: 15px;

			/* Mobile */

			@include mobile {
				position: relative;
				column-count: 1;

				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 40px;
					bottom: 0;
					left: 0;
					background: linear-gradient(to bottom, rgba(253,227,218,0) 0%,rgba(253,227,218,1) 100%);
				}

				&.is-expanded {

					&:before {
						display: none;
					}

					.extra-text {
						display: inline;
					}
				}

				.extra-text {
					display: none;
				}
			}

			em {
				display: block;
				font-family: $noe;
				font-size: 17px;
				font-style: normal;

				&:after {
					content: '';
					display: inline-block;
					width: 100%;
					height: 70px;
					vertical-align: middle;

					/* Mobile */

					@include mobile {
						display: none;
					}
				}
			}
		}

		.js-toggle-text-limit {
			color: $lightred;
		}
	}
}

/* ------------------------------------------------------------ *\
	Section Pattern
\* ------------------------------------------------------------ */

.section-pattern {
	background: url(../images/pattern.png) no-repeat center center / cover;

	/* Mobile */

	@include mobile {
		background: url(../images/pattern-mobile.png) no-repeat center center / cover;
	}
}
