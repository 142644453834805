.ie9 img[src$=".svg"] {
  width: 100%; 
}
/* 2 */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$=".svg"] {
    width: 100%; 
  }
}

/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
	min-width: 320px;
	font-family: $font-family-sans-serif;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $red;
	background: #fde3da;
}

img {
	max-width: 100%;
	height: auto;
}

a {
	color: inherit;
}

figure {
	display: block;
	margin: 0;
}

h3 {
	font-family: $noe;
	font-size: 27px;
	line-height: 1.2;
	font-weight: 400;

	/* Tablet Portrait */

	@include tablet-portrait {
		font-size: 21px;
	}

	/* Mobile */

	@include mobile {
		font-size: 22px;
	}
}
h4 {
	font-family: $noe;
	font-size: 20px;
	line-height: 1.2;
	font-weight: 400;

	/* Tablet Portrait */

	@include tablet-portrait {
		font-size: 15px;
	}
}

:focus {
	outline: none !important;
}

ul,
ol,
dl {
	padding: 0;
}

* {

	&,
	&:before,
	&:after {
		box-sizing: border-box;
		moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-smoothing: antialiased;
	}
}

ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-top: 0;

	&:last-child {
		margin-bottom: 0;
	}
}
