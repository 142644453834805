/* ------------------------------------------------------------ *\
	Map
\* ------------------------------------------------------------ */

.map-container {
	padding: 30px 0;

	/* Mobile */

	@include mobile {
		.selectric {

			.label {
				font-family: $noe;
				font-size: 17px;
			}
		}
	}

	&__content {
		position: relative;
		float: left;
		width: calc(100% - 390px);
// 		padding-top: 60.8%;
		padding-top: 40.8%;
		
		/* Tablet Portrait */

		@include tablet-portrait {
			width: calc(100% - 220px);
		}

		/* Mobile */

		@include mobile {
			float: none;
			width: auto;
			padding-top: 73.44%;
			margin: 0 -15px;
		}
	}
	&__aside {
		float: right;
		width: 390px;
		padding: 0 95px 0 30px;
		margin-top: -5px;

		/* Tablet Portrait */

		@include tablet-portrait {
			width: 220px;
			padding: 0 0 0 20px;
		}

		/* Mobile */

		@include mobile {
			float: none;
			width: 100%;
			padding: 0;
			margin: 0 0 20px;
		}
	}

	&:after {
		@include cf;
	}
}

.map {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
