/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
	padding: 51px 0;
	text-align: center;

	/* Mobile */

	@include mobile {
		padding: 20px 0;
	}

	&__logo {
		display: inline-block;
		width: 41px;
		height: 24px;
		margin-top: 40px;
		vertical-align: middle;
		background-image: url(../images/footer-logo.png);
		background-size: 100% 100%;

		/* Retina Display */

		@include retina {
			background-image: url(../images/footer-logo@2x.png);
		}

		/* Mobile */

		@include mobile {
			margin-top: 20px;
		}
	}
}
