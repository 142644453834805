/* ------------------------------------------------------------ *\
	Popup
\* ------------------------------------------------------------ */

.popup {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 40px 0;
	overflow-x: hidden;
	overflow-y: auto;
	opacity: 0;
	visibility: hidden;
	font-size: 0;
	white-space: nowrap;
	text-align: center;
	background: rgba(253, 227, 218, 0.3);
	z-index: 6;
	transition: opacity .3s, visibility .3s;

	h3 {
		margin-bottom: 22px;

		/* Mobile */

		@include mobile {
			margin-bottom: 12px;
		}
	}
	p {
		margin-bottom: 18px;
	}

	&--visible {
		opacity: 1;
		visibility: visible;
	}

	&:before {
		content: '';
		display: inline-block;
		width: 0;
		height: 100%;
		vertical-align: middle;
	}

	&__close {
		position: absolute;
		top: 33px;
		right: 40px;
		transition: opacity .3s;

		/* Mobile */

		@include mobile {
			top: 17px;
			right: 17px;
		}

		&:hover {
			opacity: .7;
		}
	}
	&__content {
		position: relative;
		display: inline-block;
		width: calc(100% - 30px);
		max-width: 747px;
		padding: 49px 40px 53px;
		margin: 0 15px;
		vertical-align: middle;
		font-size: $font-size-base;
		white-space: normal;
		text-align: left;
		background: rgba(255, 255, 255, 0.9);

		/* Mobile */

		@include mobile {
			padding: 39px 20px;
		}
	}

	/*  Modifier: Popup Text */

	&--text {

		img {
			margin-top: 13px;

			/* Mobile */

			@include mobile {
				margin: 0;
				transform: scale(.75);
			}
		}

		.popup__content {
			text-align: center;
		}
	}

	/*  Modifier: Popup Alt */

	&--alt {

		h3 {
			margin-bottom: 34px;

			/* Mobile */

			@include mobile {
				margin-bottom: 17px;

				br {
					display: none;
				}
			}
		}
		img {
			margin: 0;
		}
		i {
			margin-bottom: 29px;

			/* Mobile */

			@include mobile {
				margin-bottom: 20px;
			}
		}

		.popup__content {
			padding: 128px 40px 108px;

			/* Mobile */

			@include mobile {
				padding: 55px 20px 42px;
			}
		}
	}
}
