/* ------------------------------------------------------------ *\
	Accordion
\* ------------------------------------------------------------ */

.accordion {

	/* Mobile */

	@include mobile {
		h3 {
			font-size: 20px;
			margin-bottom: 5px;
			transition: color .3s;
		}

		&__section {
			padding: 10px 13px;
			border: 1px solid $red;

			& + & {
				margin-top: 13px;
			}

			&.accordion__section--expanded {

				h3 {
					color: $lightred;
					text-decoration: underline;
				}

				.accordion__head {

					&:before {
						transform: rotate(-45deg);
					}

					span {
						background: $lightred;
					}
				}
				.accordion__body {
					max-height: 600px;
					padding-top: 23px;
				}
			}
		}
		&__head {
			position: relative;
			padding-right: 55px;

			&:before {
				content: '';
				position: absolute;
				width: 7px;
				height: 7px;
				top: 0;
				right: 0;
				border-top: 1px solid $red;
				border-right: 1px solid $red;
				transform: rotate(135deg);
				transition: transform .3s;
			}

			p {
				line-height: 1.4;
			}

			span {
				position: absolute;
				width: 42px;
				height: 42px;
				top: 5px;
				right: 18px;
				border-radius: 50%;
				font-size: 11px;
				line-height: 43px;
				letter-spacing: 0.01em;
				color: #fde3da;
				text-transform: uppercase;
				text-align: center;
				background: $red;
				transition: background .3s;
			}
		}
		&__body {
			max-height: 0;
			padding-top: 0;
			overflow: hidden;
			transition: max-height .3s, padding .3s;
		}
	}
}
