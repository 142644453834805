/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^="list"] {
	list-style-type: none;
}

/* ------------------------------------------------------------ *\
	List Buttons
\* ------------------------------------------------------------ */

.list-buttons {
	font-size: 0;
	text-align: right;

	/* Mobile */

	@include mobile {
		margin-bottom: 13px;
	}

	li {
		display: inline-block;
		vertical-align: middle;

		/* Mobile */

		@include mobile {
			display: block;
		}

		+ li {
			margin-left: 22px;

			/* Mobile */

			@include mobile {
				margin: 9px 0 0;
			}
		}
	}
}



/* ------------------------------------------------------------ *\
	List Townhouses
\* ------------------------------------------------------------ */

.list-townhouses {
	padding-top: 17px;
	margin: 0 -15px -15px;
	color: $red;

	&:after {
		@include cf;
	}

	h4 {
		position: relative;
		padding-right: 40px;
		margin-bottom: 8px;
		transition: color .3s;

		/* Tablet Portrait */

		@include tablet-portrait {
			padding-right: 20px;
		}

		strong {
			position: relative;
			display: inline-block;
			font-weight: 400;

			&:before {
				content: '';
				position: absolute;
				width: 0;
				height: 1px;
				bottom: 2px;
				left: 0;
				background: $lightred;
				transition: width .3s;
			}
		}

		span {
			position: absolute;
			display: inline-block;
			width: 36px;
			height: 36px;
			top: -6px;
			right: 0;
			vertical-align: middle;
			border-radius: 50%;
			font-family: $brother;
			font-size: 10px;
			line-height: 37px;
			color: #fde3da;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			background: $red;
			transition: background .3s;

			/* Tablet Portrait */

			@include tablet-portrait {
				top: auto;
				right: 50%;
				margin-right: -18px;
				bottom: calc(100% + 5px);
			}
		}
	}
	ul {
		list-style-type: none;

		/* Tablet Portrait */

		@include tablet-portrait {
			font-size: 10px;
		}

		li {
			padding: 3px 0;
			border-bottom: 1px solid $red;

			+ li {
				margin-top: 4px;
			}
		}
		aside {
			float: right;
		}
	}
	> li {
		position: relative;
		float: left;
		width: 20%;
		padding: 38px 15px 0;
		margin-bottom: 35px;
		cursor: pointer;

		&:before {
			content: '';
			position: absolute;
			width: 8px;
			height: 26px;
			top: 0;
			left: 15px;
			opacity: 0;
			background-image: url(../images/icons/ico-arrow-up.png);
			background-size: 100% 100%;
			transition: opacity .3s;

			/* Retina Display */

			@include retina {
				background-image: url(../images/icons/ico-arrow-up@2x.png);
			}
		}
		&:nth-child(5n+1) {
			clear: both;
		}
	}

	.active {

		&:before {
			opacity: 1;
		}

		h4 {
			color: $lightred;

			strong {

				&:before {
					width: 100%;
				}
			}
			span {
				background: $lightred;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	List Filters
\* ------------------------------------------------------------ */

.list-filters {
	font-family: $noe;
	font-size: 20px;
	color: $red;

	ul {
		max-height: 0;
		font-size: 15px;
		overflow: hidden;
		font-family: $brother;
		transition: max-height .3s;
		list-style-type: none;

		 li {
		 	margin-bottom: 4px;
		 }
	}
	> li {
		position: relative;
		padding: 4px 0 0;
		border-bottom: 1px solid $red;

		&.is-expanded {

			ul {
				max-height: 800px;
			}

			.js-filter-toggle {
				color: $lightred;

				&:before {
					bottom: 10px;
					transform: rotate(-45deg);
				}
			}
		}

		+ li {
			margin-top: 5px;
		}
	}
	a {
		display: block;
		text-decoration: none;
		transition: color .3s;

		&:hover {
			color: $lightred;
		}
		&.is-active {
			color: $lightred;
		}
	}

	.js-filter-toggle {

		&:before {
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			bottom: 8px;
			right: 2px;
			border-top: 1px solid $red;
			border-right: 1px solid $red;
			transform: rotate(135deg);
			transition: bottom .3s, transform .3s;
			z-index: 1;
		}
	}
}

/* ------------------------------------------------------------ *\
	List Info
\* ------------------------------------------------------------ */

.list-info {
	margin: 0;
	text-align: center;

	/* Mobile */

	@include mobile {
		margin-bottom: 4px;
		font-size: 12px;
	}

	li {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		margin: 0 13px;

		/* Mobile */

		@include mobile {
			display: block;
			margin: 0;
		}

		+ li {

			/* Mobile */

			@include mobile {
				margin-top: 4px;
			}

			&:before {
				content: '•';
				position: absolute;
				top: 0;
				left: -17px;

				/* Mobile */

				@include mobile {
					display: none;
				}
			}
		}
	}
	a {
		text-decoration: none;
	}

	/*  Modifier: List Info Alt */

	&--alt {

		li {
			margin: 0 8px;

			+ li {

				&:before {
					display: none;
				}

				a {
					text-decoration: underline;
					transition: color .3s;

					&:hover {
						color: $lightred;
					}
				}
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	List Info Alternative
\* ------------------------------------------------------------ */

.list-info-alternative {
	margin: 0;

	/* Mobile */

	@include mobile {
		li {
			position: relative;
			display: inline-block;
			vertical-align: middle;

			+ li {
				margin-left: 17px;

				&:before {
					content: '|';
					position: absolute;
					top: 0;
					left: -13px;
				}
			}
		}
	}
}
.list-townhouses ul li.contract {
    padding: 3px 0;
    border-bottom: none;
    color: #e9473c;
    font-size: 10px!important;

   } 
 
 ul.list-info-alternative li.contract {
    padding: 3px 0;
    border-bottom: none;
    color: #e9473c;
    font-size: 10px!important;
	margin-top: 4px;
   }   