/* Breakpoints Mixins */

@mixin media($breakpoint) {
	@media(max-width: $breakpoint){
		@content
	}
}

@mixin small-desktop {
	@include media($small-desktop){
		@content;
	}
}

@mixin tablet {
	@include media($tablet){
		@content;
	}
}

@mixin tablet-portrait {
	@include media($tablet-portrait){
		@content;
	}
}

@mixin mobile {
	@include media($mobile){
		@content;
	}
}

@mixin retina {
	@media only screen and ( -webkit-min-device-pixel-ratio: 2 ), only screen and ( min-device-pixel-ratio: 2 ), only screen and ( min-resolution: 192dpi ) {
		@content;
	}
}
