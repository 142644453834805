/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

/*  Fields & Textareas  */

.field {
	display: block;
	width: 100%;
	height: 26px;
	padding: 0;
	border: 0;
	border-bottom: 1px solid $red;
	border-radius: 0;
	font-family: inherit;
	font-size: 15px;
	color: $red;
	background: none;
	appearance: none;

	&::-webkit-input-placeholder {
		opacity: 1 !important;
		color: $red !important;
	}
	&::-moz-placeholder {
		opacity: 1 !important;
		color: $red !important;
	}
	&:-moz-placeholder {
		opacity: 1 !important;
		color: $red !important;
	}
	&:-ms-input-placeholder {
		opacity: 1 !important;
		color: $red !important;
	}

	&--textarea {
		height: 113px;
		padding: 10px;
		border: 1px solid $red;
		resize: none;
	}
	
	&.error{ background: rgba(255, 0, 0, 0.4); &::placeholder{ color: #fff !important; } }
}

/*  Select  */

.selectric {
	padding: 0;
	border: 0;
	background: none;

	.button {
		display: none;
	}
	.label {
		position: relative;
		display: block;
		height: 26px;
		margin: 0;
		border-bottom: 1px solid $red;
		font-size: 15px;
		line-height: 26px;
		font-weight: 400;
		color: $red;

		&:before {
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			top: 7px;
			right: 3px;
			border-top: 1px solid $red;
			border-right: 1px solid $red;
			transform: rotate(135deg);
		}
	}
}

.selectric-items {
	border: 0;
	box-shadow: none;
	background: #fde9e2;

	li {
		padding: 5px 15px;
		font-size: 15px;
		color: $red;
		transition: color .3s;

		&:hover {
			color: $lightred;
			text-decoration: underline;
			background: none;
		}
		&.selected {
			color: $lightred;
			text-decoration: underline;
			background: none;
		}
	}
}

/* Checkboxes */

.checkbox {
	position: relative;

	input {
		position: absolute;
		opacity: 0;
		visibility: hidden;

		&:checked {

			+ label {

				span {

					&:before {
						content: '';
						position: absolute;
						width: 6px;
						height: 6px;
						top: 50%;
						left: 50%;
						margin: -3px 0 0 -3px;
						border-radius: 50%;
						background: $red;
					}
				}
			}
		}
	}

	&__label {
		display: block;
		padding-left: 24px;
		font-size: 15px;
		cursor: pointer;

		span {
			position: absolute;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			top: 2px;
			left: 0;
			border: 1px solid $red;
		}
	}
}
