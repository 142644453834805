/* ------------------------------------------------------------ *\
	Actions
\* ------------------------------------------------------------ */

.actions {

	/* Mobile */

	@include mobile {
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		padding: 14px 15px;
		text-align: center;
		background: #fde3da;
		transition: opacity .3s, visibility .3s;
		z-index: 3;

		&.active {
			opacity: 0;
			visibility: hidden;
		}
	}
}
