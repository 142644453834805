/* ------------------------------------------------------------ *\
	Fonts
\* ------------------------------------------------------------ */

@font-face {
    font-family: 'Noe Display';
    src: url('../fonts/noe-display/NoeDisplay-Regular.eot');
    src: url('../fonts/noe-display/NoeDisplay-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/noe-display/NoeDisplay-Regular.woff2') format('woff2'),
         url('../fonts/noe-display/NoeDisplay-Regular.woff') format('woff'),
         url('../fonts/noe-display/NoeDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
