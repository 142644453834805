/* ------------------------------------------------------------ *\
	Global
\* ------------------------------------------------------------ */

[class*="slides"] {

	&:not(.slick-initialized) {
		overflow: hidden;
		white-space: nowrap;

		> * {
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			white-space: normal;
		}
	}
}

.slider-paging {
	text-align: center;

	li {
		display: inline-block;
		width: 9px;
		height: 9px;
		vertical-align: middle;
		border: 1px solid $red;
		border-radius: 50%;
		cursor: pointer;
		transition: background .3s;

		+ li {
			margin-left: 30px;
		}

		&:hover,
		&.slick-active {
			background: $red;
		}
	}
	button {
		display: none;
	}
}

/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider {


	&.home {

		.slider__inner {
			.slider__image {
				height: calc(100vh - 134px);
				opacity: 0;
				/* Mobile */
				@include mobile {
					height: calc(100vh - 72px);
				}
			}
		}
	
		.slick-track {
			height: calc(100vh - 134px);
			/* Mobile */
			@include mobile {
				height: calc(100vh - 72px);
			}
		}
		.slick-active {
	
			.slider__inner {
				height: calc(100vh - 134px);
				/* Mobile */
	
				@include mobile {
					height: calc(100vh - 72px);
				}
			}
		}
	
		.slick-prev,
		.slick-next {
			display: none !important;
		}
		
	} // home

	&__image {
		height: 58.28vw;
		background-size: cover;
		background-position: center center;

		&.home {
			height: calc(100vh - 134px);
		}

		/* Mobile */

		@include mobile {
			height: calc(100vh - 72px);
		}
	}
	&__inner {
		overflow: hidden;
	}

	.slick-track {
		height: 58.28vw;

		/* Mobile */

		@include mobile {
			height: calc(100vh - 72px);
		}
	}
	.slick-slide {
		opacity: 1 !important;
	}
	.slick-active {

		.slider__inner {
			height: 58.33vw;
			transition: height .6s;

			&.home {
				height: calc(100vh - 134px);
			}

			/* Mobile */

			@include mobile {
				height: calc(100vh - 72px);
			}
		}
	}

	.slick-prev,
	.slick-next {
		display: none !important;
	}
}

/* ------------------------------------------------------------ *\
	Slider Default
\* ------------------------------------------------------------ */

.slider-default {
	position: relative;
	margin-bottom: 34px;

	/* Mobile */

	@include mobile {
		margin: 0 -15px 23px;
	}

	&__btn {
		position: absolute;
		top: 0;
		right: 97px;
		z-index: 2;

		/* Tablet Portrait */

		@include tablet-portrait {
			right: 40px;
		}
	}
	&__image {
		padding-top: 47.4%;
		background-position: center center;
		background-size: cover;

		/* Mobile */

		@include mobile {
			padding-top: 48.75%;
		}
	}
	&__paging {
		padding-top: 25px;
	}

	.slick-prev {
		left: 25px;
		transform: rotate(-135deg);
	}
	.slick-next {
		right: 25px;
		transform: rotate(45deg);
	}

	.slick-prev,
	.slick-next {
		position: absolute !important;
		width: 30px;
		height: 30px;
		top: 50%;
		margin-top: -15px;
		border: 0;
		border-top: 1px solid $red;
		border-right: 1px solid $red;
		background: none;
		transition: opacity .3s;
		cursor: pointer;
		z-index: 3;
		font-size: 0;
		line-height: 0;
		text-indent: -4004px;

		/* Mobile */

		@include mobile {
			display: none !important;
		}

		&:hover {
			opacity: .7;
		}
	}

	/*  Modifier: Slider Default Alt */

	&--alt {

		.slick-prev,
		.slick-next {
			border-color: #b39e95;
		}
	}
}

/* ------------------------------------------------------------ *\
	Slider Houses
\* ------------------------------------------------------------ */

.slider-houses {
	padding: 55px 0 62px;

	/* Mobile */

	@include mobile {
		padding: 30px 15px;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	&__slides {
		position: relative;

		.slick-prev {
			left: 50px;
			transform: rotate(-135deg);
		}
		.slick-next {
			right: 50px;
			transform: rotate(45deg);
		}

		.slick-prev,
		.slick-next {
			position: absolute !important;
			width: 30px;
			height: 30px;
			top: 50%;
			margin-top: -15px;
			border: 0;
			border-top: 1px solid $red;
			border-right: 1px solid $red;
			background: none;
			transition: opacity .3s;
			cursor: pointer;
			z-index: 3;
			font-size: 0;
			line-height: 0;
			text-indent: -4004px;
			
			&.slick-disabled{
				display: none!important;
			}
			
			&:hover {
				opacity: .7;
			}
		}
	}
	&__list-alt {
// 		margin-top: -2px;
		margin-top: 0px;
	}
	&__actions {
		margin-top: -14px;
		text-align: center;
		position: relative;
		z-index: 23;		

		> * {
			display: inline-block;
			vertical-align: middle;
		}
	}
	&__list {
		font-size: 12px;

		li {
			display: inline-block;
			vertical-align: middle;
			padding: 0 8px;
		}
		button {
			padding: 0;
			border: 0;
			border-radius: 0;
			color: $red;
			background: none;
			appearance: none;
		}

		.slick-active {

			button {
				font-style: italic;
			}
		}
	}

	&__prev,
	&__next {
		display: inline-block;
		vertical-align: middle;
		transition: opacity .3s;
		cursor: pointer;
		
		&.slick-disabled {
			opacity: 0;
			cursor: default;
				&:hover{
					opacity: 0;				
				}
		}

		&:hover {
			opacity: .7;
		}
	}
}
