/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */

.ico-arrow-left {
	@include icon(ico-arrow-left, 26px, 8px, true);
}
.ico-arrow-right {
	@include icon(ico-arrow-right, 26px, 8px, true);
}
.ico-check {
	@include icon(ico-check, 61px, 61px, true);

	/* Mobile */

	@include mobile {
		width: 46px;
		height: 46px;
	}
}
.ico-cross {
	position: relative;
	display: inline-block;
	width: 10px;
	height: 10px;
	vertical-align: middle;

	&:before {
		transform: rotate(-45deg);
	}
	&:after {
		transform: rotate(45deg);
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		width: 13px;
		height: 1px;
		top: 50%;
		left: -2px;
		background: $red;
	}
}
