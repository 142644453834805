/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell {
	padding: 0 $shell-gutter;

	/* Mobile */

	@include mobile {
		padding: 0 15px;
	}
}
