/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
	display: inline-block;
	width: 196px;
	height: 85px;
	vertical-align: middle;
	background-image: url(../images/logo.svg);
	background-size: 100% 100%;
	font-size: 0;
	line-height: 0;
	text-indent: -4004px;

	/* Tablet Portrait */

	@include tablet-portrait {
		width: 130px;
		height: 56px;
	}

	/* Mobile */

	@include mobile {
		width: 100px;
		height: 45px;
	}
}
