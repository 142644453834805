/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
	position: relative;
	min-height: 100vh;
	padding-top: 60px;
	overflow: hidden;

	/* Mobile */

	@include mobile {
		padding-top: 72px;
	}
}
