/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {
// 	padding: 25px 0 15px;
	padding: 25px 0;
	font-family: $noe;
	font-size: 20px;
	color: $red;
	text-align: center;

	/* Mobile */

	@include mobile {
		position: fixed;
		width: 100%;
		height: calc(100% - 72px);
		top: 72px;
		left: 0;
		padding-bottom: 72px;
		overflow-x: hidden;
		overflow-y: auto;
		transform: rotateX(90deg);
		transform-origin: 50% 0%;
		font-size: 0;
		white-space: nowrap;
		z-index: 4;
		background: #fde3da;
		transition: transform .3s;

		&.nav--visible {
			transform: rotateX(0deg);
		}

		.shell {
			height: 100%;

			&:before {
				content: '';
				display: inline-block;
				width: 0;
				height: 100%;
				vertical-align: middle;
			}
		}
	}

	ul {
		position: relative;
		top: 2px;
		list-style-type: none;

		/* Mobile */

		@include mobile {
			display: inline-block;
			width: 100%;
			top: 0;
			vertical-align: middle;
			font-size: 20px;
			white-space: normal;
		}
	}
	li {
		display: inline-block;

		/* Mobile */

		@include mobile {
			display: block;
		}

		&.active {

			a {
				color: $lightred;

				&:before {
					width: 100%;
					left: 0;
				}
			}
		}

		+ li {
			margin-left: 127px;

			/* Tablet Portrait */

			@include tablet-portrait {
				margin-left: 60px;
			}

			/* Mobile */

			@include mobile {
				margin: 18px 0 0;
			}
		}
	}
	a {
		position: relative;
		display: block;
		text-decoration: none;
		transition: color .3s;

		/* Mobile */

		@include mobile {
			display: inline-block;
			vertical-align: middle;
		}

		&:before {
			content: '';
			position: absolute;
			width: 0;
			height: 1px;
			bottom: 0;
			left: 50%;
			background: $lightred;
			transition: width .3s, left .3s;
		}
		&:hover {
			color: $lightred;

			&:before {
				width: 100%;
				left: 0;
			}
		}
	}
}

/* ------------------------------------------------------------ *\
	Nav Btn
\* ------------------------------------------------------------ */

.nav-btn {

	/* Mobile */

	@include mobile {
		display: inline-block;
		width: 14px;

		&.nav--visible {

			span {

				&:nth-child(1) {
					top: 5px;
					transform: rotate(45deg);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-child(3) {
					top: -5px;
					transform: rotate(-45deg);
				}
			}
		}

		span {
			position: relative;
			display: block;
			height: 2px;
			top: 0;
			background: $red;
			transition: top .3s, transform .3s, opacity .3s;

			+ span {
				margin-top: 3px;
			}
		}
	}
}
