.interactive-master{
	position: relative;
	.pin{
		cursor: pointer;
		&.disabled{
			cursor: default;	
			&:after{
				content: "Sold";
				position: absolute;
				left: 50%;
				top:50%;
				transform: translate(-50%,-50%);background: #fde3da;
				padding: 10px;
			}
			&.round--18{
				&:after{
					left: 63%;
					top: 61%;
				}
			}
		}
		&.contract{
			&:after{
				content: "Under Contract";
				position: absolute;
				left: 5px;
				top:3px;
			    font-size: 11px;
				text-transform: uppercase;
				color: #e9473c;
			}
			&.round--18{
				&:after{
					top: auto;
					bottom: 3px;
				}
				
			}
		}
	}
	.round{
		position: absolute;
		background: transparent;
	    width: 14.5%;
		height: 11%;
		top:5%;
		left: 5%;
		cursor: pointer;
		&--type1{
		    width: 15%;
			height: 10%;
		}
		&--type2{
			width: 16.5%;
			height: 8.5%;
		}
		&--type3{
			width: 9%;
			height: 20.5%;
		}
		&--type4{
		    width: 18.5%;
			height: 10%;
		}
		
		&--1{
			top: 64.6%;
			left: 78.5%;
		}
		&--2{
			top: 54.6%;
			left: 78.5%;
		}
		&--3{
			top: 28%;
			left: 61.9%;
		}
		&--4{
			top: 19%;
			left: 53.5%;
		}
		&--5{
			top: 11%;
			left: 53.5%;
		}
		&--6{
			top: 28%;
			left: 53%;
		}
		&--7{
			top: 11%;
			left: 29.9%;
		}
		&--8{
			top: 19%;
			left: 29.9%;
		}
		&--9{
			top: 27.5%;
			left: 29.9%;
		}
		&--10{
			top: 35.5%;
			left: 29.9%;
		}
		&--11{
			top: 51%;
			left: 29.9%;
		}
		&--12{
			top: 59%;
			left: 29.9%;
		}
		&--13{
			top: 64.7%;
			left: 2.9%;
		}
		&--14{
			top: 54.7%;
			left: 2.9%;
		}
		&--15{
			top: 44.7%;
			left: 2.9%;
		}
		&--16{
			top: 34.7%;
			left: 2.9%;
		}
		&--17{
			top: 24.7%;
			left: 2.9%;
		}
		&--18{
		    top: 13%;
			left: 7%;
		}
		&--19{
			top: 31.2%;
			left: 78.5%;
		}
		&--20{
			top: 21%;
			left: 78.5%;
		}
		&--21{
			top: 11%;
			left: 78.5%;
		}
		
	}
	
}