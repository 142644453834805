/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {
	padding: 15px 0 60px;

	/* Tablet Portrait */

	@include tablet-portrait {
		padding-bottom: 20px;
	}

	/* Mobile */

	@include mobile {
		.col {

			+ .col {
				margin-top: 13px;
			}
		}
	}

	&__head {
		margin-bottom: 28px;
		margin-top: 20px;
		/* Mobile */

		@include mobile {
			margin-bottom: 16px;
		}
	}
	&__row {

		& + & {
			margin-top: 30px;

			/* Mobile */

			@include mobile {
				margin-top: 16px;
			}
		}
	}
	&__label {
		display: block;
		padding-top: 3px;
		margin-bottom: 3px;
	}
	&__actions {
		padding-top: 24px;
	}
	&__btn {
		display: block;
		width: 100%;
		margin-top: 18px;
	}
}
