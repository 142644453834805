/* ------------------------------------------------------------ *\
	Btn
\* ------------------------------------------------------------ */

.btn {
	display: inline-block;
	height: 30px;
	padding: 0 17px;
	border: 0;
	border-radius: 0;
	vertical-align: middle;
	line-height: 30px;
	font-size: 12px;
	letter-spacing: 0.035em;
	text-transform: uppercase;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	appearance: none;

	/*  Modifier: Btn Red */

	&--red {
		color: #fde3da;
		background: $lightred;
		transition: background .3s;

		&:hover {
			background: $red;
		}
	}

	/*  Modifier: Btn Block */

	&--block {
		display: block;
	}
}
