/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Hidden  */

.hidden {
	display: none !important;
}

/*  Columns  */

.cols {
	margin: 0 -15px;

	&:after {
		@include cf;
	}
}

.col {
	float: left;
	width: 100%;
	padding: 0 15px;

	/* Mobile */

	@include mobile {
		float: none;
	}

	&--1of2 {
		width: 50%;

		/* Mobile */

		@include mobile {
			width: 100%;
		}
	}
}

/*  Reponsive visibility  */

.visible-xs {
	display: none !important;

	/* Mobile */

	@include mobile {
		display: block !important;
	}
}

.hidden-xs {

	/* Mobile */

	@include mobile {
		display: none !important;
	}
}
